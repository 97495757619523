export const environment = {
  production: false,
  baseApiUrl: 'https://tcocom-dev-cvx.azurewebsites.net',
  apiUrl: 'https://tcocom-test.azure.chevron.com/user_impersonation',
  calConfig: {
    autoSignIn: true,
    popupForLogin: false,
    cacheLocation: 'sessionStorage',
    instance: 'https://login.microsoftonline.com/',
    tenantId: 'fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
    clientId: 'f86f8ebe-ad15-4d7a-bcc4-66dd5030ce91',
    redirectUri: window.location.origin,
    oidcScopes: ["openid", "profile", "User.Read", "offline_access"],
    graphScopes: ["User.Read", "User.Read.All"]
  },
  appInsights: {
    instrumentationKey: 'aaa7e49b-85f2-419e-b9ce-fc54d7d32ab2'
  },
  sddUserGuide: "https://chevron.sharepoint.com/sites/TCOPowerAppsProjects/SDD/Shared%20Documents/SDD%20Documents/SDD_UserGuide.pdf",
  powerBIReports: "https://app.powerbi.com/groups/me/apps/bf9f14be-b1cd-4395-8855-d2ff40dd9f13?ctid=fd799da1-bfc1-4234-a91c-72b3a1cb9e26"
};
